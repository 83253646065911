import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { logEvent } from "services/amplitude.service";
import { authAPI } from "services/apis.service";
import { useAuth } from "hooks/use-auth.hook";
import { useUserData } from "features/User/";

import { wordpressURL } from "config";
import ServiceSignin from "components/base/login-signup/ServiceSignin";
import Terms from "components/base/login-signup/Terms";

export default function Login() {
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { instance, accounts, inProgress } = useMsal();
  const { microsoftUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (email === "" || password === "") {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [email, password]);

  const { setCategories } = useUserData();

  const { handleExternalLogin, handleRedirectLogin, handleLoginUser } = useAuth(
    {
      handleLoading: setloading,
      handleData: setCategories,
      toast,
    }
  );

  async function onSubmitLogin(e) {
    e.preventDefault();
    setloading(true);

    try {
      const { data } = await axios.post(authAPI.login, {
        emailOrUserName: email,
        password,
      });

      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("login", true);
      setCategories(data);

      handleLoginUser({ user: data });
      localStorage.setItem("email", data.email);
    } catch (error) {
      console.error(error);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
        setloading(false);
        return;
      }
      toast.error(error.response.data);
    } finally {
      setloading(false);
    }
  }

  useEffect(() => {
    if (inProgress === "none") {
      instance
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            // Assuming the response contains the accessToken property.
            // const accessToken = response.accessToken;
            // console.log("Access token:", response);
            // Now you can use the access token to call a protected API or perform other actions.
          }
        })
        .catch((error) => {
          // Handle or log error
          console.error(error);
        });
    }
  });

  useEffect(() => {
    const handleResponse = async () => {
      if (accounts.length > 0 && microsoftUser) {
        const account = accounts[0];
        const request = {
          scopes: ["user.read"],
          account: account,
        };

        try {
          const accessTokenResponse = await instance.acquireTokenSilent(
            request
          );
          handleExternalLogin(
            accessTokenResponse.accessToken,
            "Microsoft",
            authAPI.microsoftLogin
          );
        } catch (error) {
          if (
            error.name === "InteractionRequiredAuthError" ||
            (error.message && error.message.includes("interaction required"))
          ) {
            instance.acquireTokenRedirect(request);
          } else {
            console.error(error);
          }
        }
      }
    };

    handleResponse();
  }, [instance, accounts, microsoftUser, handleExternalLogin]);

  useEffect(() => {
    const handleAuthResponse = async () => {
      const authResponse = await instance.handleRedirectPromise();
      if (authResponse) {
        const accessToken = authResponse.accessToken;
      }
    };

    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const code = params.get("code");
    const error = params.get("error");
    if (error === "access_denied") {
      window.location.href = "/signin";
    }
    // ... now you can use 'code' and 'state' as needed
    if (code) {
      handleAuthResponse();
    }

    // Typically, you would then exchange the code for tokens using your server
  }, []);

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          // console.log(response);
        } else {
          // No user is logged in
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  }, []);

  useEffect(() => {
    handleRedirectLogin("clever", authAPI.cleverLogin);
    handleRedirectLogin("classlink", authAPI.classlinkLogin);
  }, [location.search]);

  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bgcolor: theme.palette.secondary.main,
          p: 2,
          display: "flex",
          justifyContent: "end",
          alignItems: "flex-end",
          gap: 2,
        }}
      >
        <Typography variant="body2">Don't have an account yet?</Typography>
        <Button LinkComponent={Link} to="/signup" color="primary">
          Sign Up
        </Button>{" "}
      </Box>
      <Typography variant="h3">Sign In</Typography>
      <Stack direction="column" gap={2}>
        <ServiceSignin handleLoading={setloading} />
        <Divider>
          <Chip label="OR" size="small" />
        </Divider>
        <Box
          component="form"
          onSubmit={onSubmitLogin}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            px: 5,
          }}
        >
          <TextField
            id="email-username"
            placeholder="Email Address or Username"
            value={email}
            inputProps={{
              type: "email",
            }}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="password"
            placeholder="Password"
            value={password}
            inputProps={{
              type: "password",
            }}
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Stack direction="row" gap={2} justifyContent="end">
            <Button
              variant="outlined"
              LinkComponent={Link}
              to={process.env.REACT_APP_PRODUCTION_POLICY_URL}
            >
              Back to home
            </Button>
            <LoadingButton
              loading={loading}
              disabled={isButtonDisabled}
              type="submit"
              variant="contained"
            >
              Sign In
            </LoadingButton>
          </Stack>
          <Box></Box>
        </Box>
        <Button LinkComponent={Link} variant="text" to="/forgot-password">
          Forgot Password
        </Button>
        <Divider />
        <Terms logEvent={logEvent} wordpressURL={wordpressURL} />
      </Stack>
    </React.Fragment>
  );
}
