import Axios from "axios";
import { authAPI } from "services/apis.service";
import { setupCache, buildWebStorage } from "axios-cache-interceptor";

const instance = Axios.create({});
instance.CancelToken = Axios.CancelToken;
instance.isCancel = Axios.isCancel;

export const axiosInstance = setupCache(instance, {
  storage: buildWebStorage(localStorage, "sooth-api-cache:"),
  debug: console.log,
  methods: ["get", "post"],
});

let isRefreshing = false;
let refreshQueue = [];

const getUserFromStorage = () => {
  let user = sessionStorage.getItem("user") || localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

const saveUserToStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const onRequest = (config, logoutUserFromApp) => {
  const user = getUserFromStorage();
  if (!user) {
    localStorage.setItem("url", window.location.href);
    logoutUserFromApp();
    return Promise.reject(new Error("No user in storage. Logging out."));
  }
  if (user.token) {
    config.headers["Authorization"] = `Bearer ${user.token}`;
  }
  return config;
};

const onResponse = (response, updateReduxState) => {
  if (updateReduxState && typeof updateReduxState === "function") {
    updateReduxState({}, false);
  }
  return response;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponseError = async (
  error,
  updateReduxState,
  logoutUserFromApp,
  isSubscriptionExpired
) => {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    if (isSubscriptionExpired)
      window.location.href = window.location.origin + "/profile/subscription";
  }
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    let user = getUserFromStorage();
    if (!user) {
      return Promise.reject(error);
    }
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const { data } = await Axios.post(authAPI.refreshToken, {
          refreshToken: user.refreshToken,
          accessToken: user.token,
        });
        user = {
          ...user,
          token: data.accessToken,
          refreshToken: data.refreshToken,
        };
        saveUserToStorage(user);
        if (updateReduxState && typeof updateReduxState === "function") {
          updateReduxState(user, true);
        }
        originalRequest.headers["Authorization"] = `Bearer ${data.accessToken}`;
        localStorage.setItem("url", window.location.href);
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Invalid/no token");
        logoutUserFromApp();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
        refreshQueue.forEach((resolve) => resolve());
        refreshQueue = [];
      }
    } else {
      return new Promise((resolve, reject) => {
        refreshQueue.push(() => {
          originalRequest.headers["Authorization"] = `Bearer ${
            getUserFromStorage().token
          }`;
          axiosInstance(originalRequest).then(resolve).catch(reject);
        });
      });
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  updateReduxState,
  logoutUserFromApp,
  isSubscriptionExpired
) => {
  axiosInstance.interceptors.request.use(
    (config) => onRequest(config, logoutUserFromApp),
    onRequestError
  );
  axiosInstance.interceptors.response.use(
    (response) => onResponse(response, updateReduxState),
    (error) =>
      onResponseError(
        error,
        updateReduxState,
        logoutUserFromApp,
        isSubscriptionExpired
      )
  );
};
