import { Stack, Link, Box, Typography, Divider, Tooltip, IconButton, useTheme } from "@mui/material";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import { useIsTab } from "hooks/is-tab.hook";
import { useDispatch } from "react-redux";
import {
  setEntityName,
  setIsSourceSidebarOpen,
  setOthersNeeded,
  setSourceUrl,
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import { logEvent } from "services/amplitude.service";
import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";
import { HiOutlineLockOpen, HiOutlineLockClosed } from "react-icons/hi2";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function TableRow({
  row,
  menuClick,
  isPublicCollectionSrc,
  ...otherProps
}) {
  const isTab = useIsTab();
  const theme = useTheme();
  const dispatch = useDispatch();

  const openSidebar = () => {
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    dispatch(setCollectionSourceId(row.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: row.entityName,
        title: row.name,
        published_date: row.datePublishedDisplayText,
        url: row.url,
      })
    );
    dispatch(setCollectionId(row.collectionId));
    dispatch(setIsCitationAvailable(row.isCitationAvailable));
    dispatch(setIsSummaryAvailable(row.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(row.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const openSourceSidebar = () => {
    logEvent("click_card_header", {
      host: row.hostName,
      entity: row.entityName,
    });
    dispatch(setOthersNeeded(false));
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(row.hostName));
    dispatch(setEntityName(row.entityName));
  };

  
  const BookmarkOptions = ({ data }) => {
    return (
      <Stack
        onClick={() => {
          openSidebar();
          // logEvent("open_bookmark_popup", {
          //   url: newUrl,
          //   query: query,
          //   type: "generic",
          //   entity: data.entityName,
          //   category: category,
          //   mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
          //   score: data.score ? data.score : "NA",
          // });
        }}
        className="card-options"
        sx={{
          cursor: "pointer",
          height: "40%",
          pl: 1,
          pr: 1,
          borderRadius: 1,
          transition: "all 0.1s ease",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            "& .collection-hover": {
              opacity: "1 !important",
            },
          },
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mt={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <CollectionsLogoHover
            className="collection-hover"
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 17,
              height: 17,
              fill: "blue",
              color: "blue",
              opacity: 0,
              transition: "all 0.1s ease",
            }}
          />
        )}
        <IconButton
          aria-label="more"
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  const CardHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={openSourceSidebar}
        sx={{
          borderRadius: 1,
          px: "1rem",
          py: 0.4,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#e0e9ef",
          },
        }}
      >
        <Tooltip placement="bottom-start">
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              width: "20rem",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "rgb(126,127,126)",
            }}
          >
            {row.entityName}
          </Typography>
        </Tooltip>
        <Stack direction="row" gap={2}>
          {row.mediaBiasRating && (
            <AllSidesRating activeCategory={row.mediaBiasRating} />
          )}
          {row.score && (
            <Box>
              <NewsguardScore score={Number(row.score)} size="small" />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
      {...otherProps}
    >
      <CardHeader />
      <Stack
        direction="column"
        gap={1}
        justifyContent="space-between"
        sx={{
          maxWidth: "100%",
          minHeight: "10.2vh",
          gridArea: "bookmark",
          pt: 2,
          px: 1,
        }}
      >
        <Typography
          component="div"
          variant="subtitle1"
          noWrap={!isTab}
          sx={{
            flexGrow: 1,
            minWidth: 0,
            overflowWrap: isTab ? "anywhere" : "unset",
          }}
        >
          <Link href={row.url} target="_blank">
            {row.name}
          </Link>
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="end" gap={1}>
          <Box>
            <Typography
              sx={{ width: "100%" }}
              component="span"
              variant="body2"
              color="text.secondary"
            >
              {row.datePublishedDisplayText} - {row.snippet}
            </Typography>
          </Box>
          <BookmarkOptions data={row} />
        </Stack>
        <Divider flexItem />
      </Stack>
    </Box>
  );
}
