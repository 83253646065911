import { Country, Region } from "../models";
import { subscriptionsAPI } from "services/apis.service";
export class GeoDataService {
  #axios;
  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  async getCountries() {
    try {
      const { data } = await this.#axios.get(
        `${subscriptionsAPI.getCountries}`
      );
      return data.map((country) => {
        return new Country(country);
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getRegions(countryId) {
    try {
      // const data = await GetState(countryId);
      const { data } = await this.#axios.get(
        `${subscriptionsAPI.getRegionsByCountry}?countryId=${countryId}`
      );
      return data.map((state) => new Region(state));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
