import { useEffect, useState } from "react";
import {
  getBookmarkedSummary,
  getSummary,
} from "../services/summarize.service";
import { useSelector } from "react-redux";
import { logEvent } from "services/amplitude.service";

const useSummarize = (
  htmlSummary,
  sethtmlSummary,
  markdownSummary,
  setmarkdownSummary,
  setLoading,
  summaryLoaded,
  setSummaryLoaded,
  selectedBookmarkOption,
  edit,
  setDisable,
  setOriginalSummary
) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [changeTooltip, setChangeTooltip] = useState(false);

  const { summaryInfoData, isSummaryAvailable, collectionSourceId } =
    useSelector((state) => state.data);
  const { user } = useSelector((state) => state.auth);

  const setErrorSummaryMessage = () => {
    setErrorMessage(
      "We're sorry, we could not fetch the summary of this article. Please copy and paste the article content here and click generate."
    );
    setmarkdownSummary("");
    sethtmlSummary("");
    setError(true);
  };

  const handleCopy = () => {
    const getSummaryData = summaryInfoData;
    const copiedData = `Publisher: ${getSummaryData.publisher} \nTitle: ${getSummaryData.title} \nPublished date: ${getSummaryData.published_date} \nLink: ${getSummaryData.url} \n\nSummary: ${markdownSummary}`;
    navigator.clipboard.writeText(copiedData).catch((error) => {
      console.error("Unable to copy data to clipboard:", error);
    });
    setChangeTooltip(true);
  };

  const handleMouseOut = (event) => {
    setTimeout(() => {
      const fromElement = event.relatedTarget;
      const tooltipButton = document.getElementById("tooltipButton");

      if (!tooltipButton?.contains(fromElement)) {
        setChangeTooltip(false);
      }
    }, 1000);
  };

  const getData = async () => {
    try {
      let data;

      if (isSummaryAvailable) {
        data = await getBookmarkedSummary(collectionSourceId, user);
        setmarkdownSummary(data[0].summary_chr);
        setOriginalSummary(data[0].summary_chr);
        setSummaryLoaded(true);
        setLoading(false);
        return;
      } else {
        data = await getSummary(summaryInfoData.url);
        logEvent("create_summary", {
          type: "url",
          url: summaryInfoData.url,
        });
      }
      const bullets = Object.keys(data)
        .filter((key) => key.startsWith("bullet"))
        .map((key) => `- ${data[key]}`);
      const markdownList = bullets.join(" \n");
      setmarkdownSummary(markdownList);
      if (
        data?.error?.includes("An error occurred")
      ) {
        setDisable(true);
        setErrorSummaryMessage();
      } else {
        setDisable(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setErrorSummaryMessage();
    } finally {
      setSummaryLoaded(true);
    }
  };

  const handleSave = async () => {
    if (
      !summaryInfoData.publisher ||
      !summaryInfoData.title ||
      !summaryInfoData.url
    ) {
      return;
    }
  };

  useEffect(() => {
    if (selectedBookmarkOption === "summary" && !summaryLoaded) getData();
  }, [selectedBookmarkOption, edit]);

  return {
    markdownSummary,
    setmarkdownSummary,
    handleSave,
    handleCopy,
    changeTooltip,
    handleMouseOut,
    error,
    errorMessage,
    setError,
  };
};

export default useSummarize;
