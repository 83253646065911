import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Modal,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { authAPI, getConfig } from "services/apis.service";
import CloseIcon from "@mui/icons-material/Close";
import { validateUserPassword } from "utils/validation.util";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ChangePassword = ({ changePassword, onClose }) => {
  const { user } = useSelector((state) => state.auth);
  const [oldPassword, setoldPassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "50vw", md: "35vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
  };

  async function onSubmitLogin(e) {
    e.preventDefault();

    if (!validateUserPassword(oldPassword)) {
      toast.error("Please enter a valid password");
      return;
    }

    if (!validateUserPassword(password)) {
      toast.error("Please enter a valid password");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password do not match");
      return;
    }

    try {
      const res = await axios.post(
        authAPI.changePasswrd,
        {
          currentPassword: oldPassword,
          newPassword: password,
          confirmPassword: confirmPassword,
          userId: user.userId,
        },
        getConfig()
      );

      if (res.data) {
        toast.success("Successfully reset password");
        onClose();
      }
    } catch (error) {
      console.error(error.response.data);
      toast.error(error.response.data);
    }
  }

  return (
    <Modal open={changePassword} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box sx={{ p: 4 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Change Password
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: 2.5, mt: 2 }}
          >
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Old Password"
              value={oldPassword}
              onChange={(e) => setoldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Old Password"
              id="old-password"
            />
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="password">New Password</InputLabel>
              <OutlinedInput
                id="new-password"
                fullWidth
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
                size="small"
              />
              <FormHelperText id="password-helper-text">
                NOTE: Password should be minimum 8 characters with at least one
                uppercase letter, one lowercase letter, one special character
                and one number.
              </FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setconfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Confirm New Password"
              id="confirm-new-password"
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "secondary.main",
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            px: 5,
            py: 3,
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              width: "45%",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmitLogin}
            sx={{
              width: "45%",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangePassword;
