import React, { useState, useEffect } from "react";
import { Box, Divider } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import useCollection from "features/Collections/hooks/use-collection.hook";

import AccessSidebar from "features/AccessSidebar";
import BookmarksTable from "features/Collections/components/Table";
import Header from "features/Collections/components/Header";
import Edit from "features/Collections/components/Popups/Edit";
import Delete from "features/Collections/components/Popups/Delete";
import Footer from "components/layout/Footer";
import { useIsTab } from "hooks/is-tab.hook";
import CollectionsTableGrid from "features/Collections/components/CollectionTable";
import BookmarkHeader from "./BookmarkHeader";
import MainPage from "features/WorkCited/components/MainPage";
import WorkCited from "features/WorkCited";

export default function Collection() {
  const [loading, setLoading] = useState();
  const [selectedCollectionOption, setSelectedCollectionOption] =
    useState("bookmarks");

  const {
    currentCollection,
    bookmarks,
    filteredBookmarks,
    setFilteredBookmarks,
    getUsersList,
    shareCollectionFolder,
    removeSharedUser,
    makeCollectionPublic,
    handleDeleteCollection,
    handleRenameCollection,
    handleRemoveAllSharedUsers,
    handleCollectionOverview,
    setBookmarks,
  } = useCollection(setLoading, selectedCollectionOption);

  const [edit, setEdit] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [accessPopup, setAccessPopup] = useState(false);
  const [filter, setFilter] = useState("");
  const [name, setName] = useState("");
  const [collectionOverview, setCollectionOverview] = useState({});
  const [sortCriteria, setSortCriteria] = useState("dateAdded");
  const [sortDirection, setSortDirection] = useState("asc");
  const { id, workcitedid } = useParams();
  const [workCitedName, setWorkCitedName] = useState("");
  const [workCitedFormatStyle, setWorkCitedFormatStyle] = useState("");

  const isTab = useIsTab();
  const {pathname} = useLocation();
  

  const handleFilterBookmark = () => {
    const filteredBookmark = bookmarks.filter(
      (bookmark) =>
        bookmark.title_chr.toLowerCase().includes(filter.toLowerCase()) ||
        bookmark.sourceName_chr.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredBookmarks(filteredBookmark);
  };

  const fetchCollectionOverview = async () => {
    try {
      const data = await handleCollectionOverview();
      setCollectionOverview(data);
    } catch (e) {
      console.error(e);
    }
  };

  const sortData = (criteria, direction, data) => {
    const sortedData = [...data].sort((a, b) => {
      let valA, valB;
      switch (criteria) {
        case "dateAdded":
          valA = new Date(a.createdDateTime_dtm);
          valB = new Date(b.createdDateTime_dtm);
          break;
        case "publishedDate":
          valA = new Date(a.sourcePublishedDateTime_dtm);
          valB = new Date(b.sourcePublishedDateTime_dtm);
          break;
        case "newsGuardRating":
          valA = a.score ? parseFloat(a.score) : -Infinity;
          valB = b.score ? parseFloat(b.score) : -Infinity;
          break;
        case "title":
          valA = a.title_chr.toLowerCase();
          valB = b.title_chr.toLowerCase();
          break;
        case "source":
          valA = a.sourceName_chr.toLowerCase();
          valB = b.sourceName_chr.toLowerCase();
          break;
        default:
          return 0;
      }

      if (valA < valB) return direction === "asc" ? -1 : 1;
      if (valA > valB) return direction === "asc" ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  const handleSort = (criteria) => {
    const newDirection =
      sortCriteria === criteria && sortDirection === "asc" ? "desc" : "asc";
    setSortCriteria(criteria);
    setSortDirection(newDirection);
    const dataToSort = filter ? filteredBookmarks : bookmarks;
    const sortedBookmarks = sortData(criteria, newDirection, dataToSort);
    if (filter) {
      setFilteredBookmarks(sortedBookmarks);
    } else {
      setBookmarks(sortedBookmarks);
    }
  };

  useEffect(() => {
    handleFilterBookmark();
  }, [filter]);

  useEffect(() => {
    fetchCollectionOverview();
  }, [id]);

  useEffect(() => {
    if(pathname.includes("work-cited")){
      setSelectedCollectionOption("workcited");
    }else{
      setSelectedCollectionOption("bookmarks");
    }
  }, [pathname]);

  return (
    <React.Fragment>
      <Box
        sx={{
          ...fillParent,
          ...scrollingBox,
          ...footerFlex,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateAreas: !isTab
              ? '"header header header" "list list list" "footer footer footer"'
              : '"header" "list" "footer"',
            maxWidth: "100%",
            mt: 3,
          }}
        >
          {!(workcitedid || pathname.includes("work-cited/create")) ? (
            <React.Fragment>
              <Box
                sx={{
                  gridArea: "header",
                }}
              >
                <Header
                  isPublic={false}
                  setAccessPopup={setAccessPopup}
                  collection={currentCollection}
                  length={filter ? filteredBookmarks.length : bookmarks.length}
                  setFilter={setFilter}
                  filter={filter}
                  setEdit={setEdit}
                  setDeletePopup={setDeletePopup}
                  collectionOverview={collectionOverview}
                  setName={setName}
                  onSort={handleSort}
                  sortCriteria={sortCriteria}
                  sortDirection={sortDirection}
                />
                <BookmarkHeader
                  setSelectedCollectionOption={setSelectedCollectionOption}
                  selectedCollectionOption={selectedCollectionOption}
                />
              </Box>

              <Box sx={{ gridArea: "list", overflowX: "auto" }}>
                {selectedCollectionOption === "workcited" ? (
                  <MainPage
                    setWorkCitedName={setWorkCitedName}
                    setWorkCitedFormatStyle={setWorkCitedFormatStyle}
                  />
                ) : (
                  <CollectionsTableGrid
                    recentCollectionSource={
                      filter ? filteredBookmarks : bookmarks
                    }
                  />
                )}
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box
                sx={{
                  gridArea: "header",
                }}
              ></Box>
              <Box
                sx={{
                  gridArea: "list",
                  overflowX: "auto"
                }}
              >
                <WorkCited
                  workCitedName={workCitedName}
                  setWorkCitedName={setWorkCitedName}
                  setWorkCitedFormatStyle={setWorkCitedFormatStyle}
                  workCitedFormatStyle={workCitedFormatStyle}
                />
              </Box>
            </React.Fragment>
          )}
        </Box>
        <Footer sx={{ gridArea: "footer", px: 0 }} />
      </Box>
      {accessPopup && (
        <AccessSidebar
          accessPopup={accessPopup}
          setAccessPopup={setAccessPopup}
          shareCollectionFolder={shareCollectionFolder}
          getUsersList={getUsersList}
          removeSharedUser={removeSharedUser}
          collection={currentCollection}
          length={bookmarks.length}
          makeCollectionPublic={makeCollectionPublic}
          handleRemoveAllSharedUsers={handleRemoveAllSharedUsers}
        />
      )}
      {edit && (
        <Edit
          edit={edit}
          setEdit={setEdit}
          name={name}
          handleRenameCollection={handleRenameCollection}
        />
      )}
      {deletePopup && (
        <Delete
          deletePopup={deletePopup}
          setDeletePopup={setDeletePopup}
          handleDeleteCollection={handleDeleteCollection}
        />
      )}
    </React.Fragment>
  );
}
