import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setfeedbackPopup } from "context/features/stylesSlice";
import InputCheckbox from "components/base/input/InputCheckbox";
import Rating from "react-rating";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { getConfig, searchAPI } from "services/apis.service";
import { logEvent } from "services/amplitude.service";
import { axiosInstance } from "lib/axios";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const PopupFeedback = () => {
  const dispatch = useDispatch();
  const { feedbackPopup } = useSelector((state) => state.styles);
  const { user } = useSelector((state) => state.auth);

  const [rating, setrating] = useState(0);
  const [improvement, setimprovement] = useState("");
  const [like, setlike] = useState("");

  const [anonymous, setanonymous] = useState(false);

  const [loading, setloading] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    if (rating === 0) {
      toast.error("Please fill the rating to submit this form.");

      return null;
    }
    setloading(true);

    try {
      const res = await axiosInstance.post(
        searchAPI.feedback,
        {
          feedbackImprovement: improvement,
          feedbackText: like,
          numStars: rating,
          isAnonymous: user ? anonymous : true,
          lastUpdatedByUserId: user ? user.userId : null,
        },
        getConfig()
      );

      setloading(false);

      toast.success("Feedback sent successfully");

      dispatch(setfeedbackPopup(false));
    } catch (error) {
      console.error(error);
      setloading(false);

      toast.error("Something went wrong, please try again later");
    }

    logEvent("submit_feedback", { feedback: "submitted" });
  }

  useEffect(() => {
    if (feedbackPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      setrating(0);
      setimprovement("");
      setlike("");
      setanonymous(false);
    }
  }, [feedbackPopup]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "75vw", md: "40vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
    padding: 3,
  };

  const onClose = () => {
    dispatch(setfeedbackPopup(false));
  };

  return (
    <Modal open={feedbackPopup} onClose={onClose}>
      <Box sx={modalStyle}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            marginBottom: "2rem",
            background:
              "linear-gradient(to right, #a1177a 0%, #3b75da 51%, #df3ab0 100%)",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "3.2rem",
            fontWeight: "bold",
          }}
          variant="h3"
        >
          We love feedback!
        </Typography>
        <Typography sx={{ fontSize: "2rem", my: 1 }} variant="h3">
          {" "}
          Your feedback on Sooth is greatly appreciated. We read everything you
          share with us.
        </Typography>
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="body1">
            What's your experience so far?
          </Typography>
          <Rating
            emptySymbol={<StarBorderIcon />}
            fullSymbol={<StarIcon sx={{ color: "#fbe900" }} />}
            onChange={(val) => setrating(val)}
            initialRating={rating}
          />
        </Stack>{" "}
        <Typography variant="body1">What needs improvement?</Typography>
        <TextField
          onChange={(e) => setimprovement(e.target.value)}
          multiline
          rows={4}
          sx={{ width: "100%", my: 2 }}
          placeholder="What needs improvement?"
        />
        <Typography variant="body1">What do you like?</Typography>
        <TextField
          value={like}
          onChange={(e) => setlike(e.target.value)}
          multiline
          rows={4}
          sx={{ width: "100%", my: 2 }}
          placeholder="What needs improvement?"
        />
        {user && (
          <InputCheckbox
            label="Please keep my feedback anonymous"
            value={anonymous}
            onChange={(e) => setanonymous(e.target.checked)}
          />
        )}
        <Button
          sx={{ display: "block", mt: 1 }}
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default PopupFeedback;
