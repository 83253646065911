import React, { useEffect } from "react";
import { Divider, Stack } from "@mui/material";
import SidebarCard from "./sidebar-card";
import SectionNews from "./section-news";
import { useSourceSearch } from "features/SourceSearch";
import { categories } from "features/SourceSearch/data/search-fields.data";
import { biasCategories } from "data/search-labels";

export const CategorySection = ({
  source,
  category,
  query,
  sectionNews,
  isLoading,
}) => {
  const {
    currentSearchTerm,
    currentSource,
    buildSearchUrl,
    startDate,
    endDate,
    currentTimeframe,
  } = useSourceSearch();

  const getCategory = () => {
    let category_key = null;

    const categoryList = source === "rating" ? biasCategories : categories;

    category_key = categoryList.filter(
      (curr_category) =>
        curr_category?.label &&
        category?.categoryName_chr &&
        curr_category.label === category.categoryName_chr
    );

    return category_key.length > 0
      ? category_key[0]
      : { label: "Unknown", value: null };
  };

  return (
    !category.isHidden_ysn && (
      <React.Fragment>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={2}
          alignItems={"stretch"}
          py={2}
        >
          <SidebarCard
            category={category}
            loading={isLoading}
            link={buildSearchUrl({
              query: currentSearchTerm,
              source: currentSource,
              category: getCategory().key,
              date: currentTimeframe,
              startDate,
              endDate,
            })}
          />
          <SectionNews
            category={getCategory().label}
            query={query}
            sectionNews={sectionNews}
            isLoading={isLoading}
          />
        </Stack>
        <Divider
          sx={{
            ml: { sm: "31vw", md: "22vw" },
            display: { xs: "none", sm: "block" },
          }}
        />
      </React.Fragment>
    )
  );
};

