import React, { useState } from 'react'
import { getBoclipsDataById, getBoclipsSearch, getBoclipsToken } from '../services/boclips.service';
import { useSourceSearch } from 'features/SourceSearch';
import { getFilterDateValueKeys } from 'utils/date-filter.util';
import { useDispatch } from 'react-redux';
import { setBoclipsUser } from 'context/features/authSlice';
import { boClipsLabel } from 'features/SourceSearch/data/search-fields.data';

const useBoclipsSearch = (setBoclipsSearch, query, setLoadingStates) => {
    const { currentTimeframe, startDate, endDate } = useSourceSearch();
    const [boclipsCard, setBoclipsCard] = useState({});
    const dispatch = useDispatch();

    const filteredDateValue = getFilterDateValueKeys(
    currentTimeframe,
    startDate,
    endDate
    );

    const fetchBoclipsToken = async () => {
      try { 
        const data = await getBoclipsToken();
        dispatch(setBoclipsUser(data));
        return data.accessToken;
      }catch(e){
        console.error(e);
        return "";
      }
    };
   
    const getBoclipsSearchData = async () => {
        try{
        const data = await getBoclipsSearch(query, filteredDateValue, 1);
        setBoclipsSearch(data.embedded.videos);
        setLoadingStates((prev) => ({
          ...prev,
          [boClipsLabel]: false,
        }));
        }catch(e){
        console.error(e);
        }
  };

  const fetchBoclipsDataById = async (id) => {
    try{
      const data = await getBoclipsDataById(id);
      setBoclipsCard(data);
    }catch(e){
      console.error(e);
    }
  }

  return {
    getBoclipsSearchData,
    fetchBoclipsToken,
    fetchBoclipsDataById,
    boclipsCard,
  };
}

export default useBoclipsSearch