import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getPublicCollectionOverview,
  getPublicCollections,
  getPublicCollectionsSource,
} from "../services/use-public-collection.service";

const useCollection = (filter) => {
  const [publicCollections, setPublicCollections] = useState([]);
  const [publicCollectionsSource, setPublicCollectionsSource] = useState([]);

  const [filteredPublicCollections, setFilteredPublicCollection] = useState([]);

  const abortControllerRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const params = useParams();

  const getPublicCollection = async () => {
    try {
      const data = await getPublicCollections(user, "");
      setPublicCollections(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPublicCollectionsSource = async (id) => {
    try {
      const data = await getPublicCollectionsSource(user, id);
      setPublicCollectionsSource(data);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePublicCollectionOverview = async (id) => {
    try {
      const data = getPublicCollectionOverview(user, id);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getPublicCollection();
  }, [params.id]);

  useEffect(() => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const getPublicCollection = async () => {
      try {
        const data = await getPublicCollections(user, filter, signal);
        setFilteredPublicCollection(data);
        return data;
      } catch (e) {
        console.error(e);
      }
    };

    getPublicCollection();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [filter]);

  return {
    publicCollections,
    fetchPublicCollectionsSource,
    publicCollectionsSource,
    handlePublicCollectionOverview,
    filteredPublicCollections,
  };
};

export default useCollection;
