import { axiosInstance } from "lib/axios";
import { searchAPI } from "./apis.service";

export const postDataToEventHub = async (eventName, eventProperties) => {
    try {
        await axiosInstance.post(searchAPI.postDataToEventHub, {
          jsonData: eventProperties,
          eventName: eventName,
        });
    }catch(e){
        console.error(e);
    }
};