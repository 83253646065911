import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import ToolkitSearchBar from "features/searchbar/toolkitsearchbar";
import DomainCheckerInfo from "features/MisinformationToolkit/components/DomainChecker/DomainCheckerInfo";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { Stack } from "@mui/system";
import { parse } from "tldts"; // Import tldts parser
import { toast } from "react-toastify";

export default function DomainCheckerPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const domainName = searchParams.get("domain");

  const domainRegex =
    /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z.]{2,6})/;

  function handleSearch(query) {
    const parsedDomain = parse(query); // Use tldts to parse the query

    if (parsedDomain.domain) {
      const extractedDomain = parsedDomain.domain;
      navigate(`/tools/domain-checker?domain=${encodeURI(extractedDomain)}`);
    } else {
      console.error("Invalid domain");
      toast.error("Invalid input. Please enter a valid domain or URL.");
    }
  }

  useEffect(() => {
    if (domainName) {
      handleSearch(domainName);
    }
  }, []);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <Stack direction="column" width={{ xs: "100%", sm: "70%" }}>
          <PageTitle>Domain Checker</PageTitle>
          <Typography>
            You no longer have to browse the internet in darkness. Domain
            Checker allows you to discover important qualitative and
            quantitative information about any website on the internet. Our
            independent third party data providers include NewsGuard, AllSides,
            Charity Navigator, Similarweb, and Whois data.
          </Typography>

          <ToolkitSearchBar
            placeholder="Domain to check..."
            handleSearch={handleSearch}
            buttonText="Check"
            value={domainName || ""}
          />
        </Stack>
        {domainName && <DomainCheckerInfo domainName={domainName} />}
      </Stack>
      <Footer sx={{px: 0}}/>
    </Box>
  );
}
