import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CategorySection } from "./components/section-category";
import LoaderPage from "components/layout/LoaderPage";
import useCategoryData from "./hooks/use-category-data.hook";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RelevantSection from "./components/relevant-section";
import useNewsData from "./hooks/use-news-data.hook";
import { useSourceSearch } from "features/SourceSearch";
import useBoclipsSearch from "./hooks/use-boclips-search.hook";
import BoclipsPlayer from "./components/boclips-player";
import { biasRating } from "data/biasRatingLabel";
import { boClipsLabel } from "features/SourceSearch/data/search-fields.data";
import { showBoclipsSearch } from "config";

export default function News() {
  const [loading, setLoading] = useState(true);
  const { currentSearchTerm, currentSource } = useSourceSearch();
  const [boclipsSearch, setBoClipsSearch] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const { source, search } = useParams();

  const { fetchNewsCategoryData, categoriesCopy } = useCategoryData(() => {});
  const { combinedSearch } = useNewsData(
    setLoading,
    search,
    categoriesCopy,
    setLoadingStates
  );
  const { getBoclipsSearchData, fetchBoclipsToken } = useBoclipsSearch(
    setBoClipsSearch,
    currentSearchTerm,
    setLoadingStates
  );

  const { categories, boclipsPopup } = useSelector((state) => state.data);

  function checkedSource(currentSource) {
    return currentSource == null ? "categories" : currentSource;
  }

  useEffect(() => {
    fetchNewsCategoryData();
  }, [source]);

  useEffect(() => {
    if(showBoclipsSearch && currentSearchTerm){
      getBoclipsSearchData(setBoClipsSearch, currentSearchTerm);
    }
  }, [currentSearchTerm]);
  
  if (!categories.length) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoaderPage />
      </Box>
    );
  };

  const augmentedCategories = [
    ...categories.slice(0, 1),
    ...(showBoclipsSearch
      ? [{ category_id: "boclips", categoryName_chr: boClipsLabel }]
      : []),
    ...categories.slice(1),
  ];

  return (
    <React.Fragment>
      <RelevantSection />
      {(currentSource === "rating" ? biasRating: augmentedCategories).map((category) => {
        const sectionNews =
          combinedSearch.find(
            (search) => search.media === category.categoryName_chr
          ) || null;

        return (
          <Box key={category.category_id}>
            <CategorySection
              category={category}
              sectionNews={
                category.category_id !== "boclips" ? sectionNews : boclipsSearch
              }
              source={checkedSource(source)}
              isLoading={loadingStates[category.categoryName_chr] || loading}
            />
          </Box>
        );
      })}
      {boclipsPopup && <BoclipsPlayer />}
    </React.Fragment>
  );
}
