import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUseToPublicCollection,
  addUserToCollection,
  deleteCollection,
  deleteSharedUser,
  getBookmarksFromCollection,
  getCollectionOverview,
  getCollections,
  getParentChainCollection,
  getSharedUserList,
  publicCollection,
  removeAllSharedUsers,
  saveCollectionFolder,
  saveCustomBookmark,
} from "../services/collection.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  setCollections,
  setIsBookmarkSidebar,
  setParentCollections,
  setReadingSummary,
  setSummary,
} from "context/features/dataSlice";
import {
  getCollectionsSource,
  getParentCollections,
} from "features/Bookmarks/services/collection.service";

const useCollection = (setLoading, selectedCollectionOption = "bookmarks") => {
  const [bookmarks, setBookmarks] = useState([]);
  const [filteredBookmarks, setFilteredBookmarks] = useState([]);
  const [currentCollection, setCurrentCollection] = useState({});
  const [parentCollectionIds, setParentCollectionIds] = useState([]);
  const [collectionIdArray, setCollectionIdArray] = useState([]);

  const {
    collections,
    isSidebarOpen,
    collectionSourceId,
    collectionId,
    isBookmarkSidebar,
  } = useSelector((state) => state.data);

  const params = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchUrlParams = searchParams.get("search")
    ? searchParams.get("search")
    : "";
  const [search] = useState(searchUrlParams);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();

  const getBookmarkData = async () => {
    try {
      const data = await getCollectionsSource(collectionId, collectionSourceId);
      if (data[0].isSummaryAvailable_ysn)
        dispatch(setSummary(data[0].summary_chr));
      if (data[0].isReadingLevelAvailable_ysn)
        dispatch(setReadingSummary(data[0].articleComprehension_chr));
    } catch (e) {
      console.error(e);
    }
  };

  const getBookmarks = async (id) => {
    const data = await getBookmarksFromCollection(user, id);
    setBookmarks(data);
    setFilteredBookmarks(data);
  };

  const redirectAndSetHeaderParams = (data) => {
    if (!id) {
      setCurrentCollection(data[0]);
    } else {
      let item = data.find((item) => item.collectionId === Number(id));
      if (item) {
        item = {
          ...item,
          collectionName_chr: item.name,
        };
        setCurrentCollection(item);
      }
    }
  };

  const getParentCollectionIds = (data) => {
    const ids = data
      .filter((collection) => collection.parentCollectionID_lng === 0)
      .map((collection) => collection.collectionID_ids);
    setParentCollectionIds(ids);
  };

  const getData = async () => {
    const data = await getCollections(user);
    let newArr = [];
    sortBy(data, ["collectionId_ids"]).forEach((item) => {
      newArr.push({
        name: item.collectionName_chr,
        value: item.collectionName_chr,
        collectionId: item.collectionID_ids,
        parentCollectionId: item.parentCollectionID_lng,
        isShared_ysn: item.isShared_ysn,
        isPublic_ysn: item.isPublic_ysn,
      });
    });
    dispatch(setCollections(newArr));
    getParentCollectionIds(data);
    redirectAndSetHeaderParams(newArr);
    setLoading(false);
  };

  const getCollArray = async () => {
    try {
      const data = await getParentChainCollection(id, user);
      const chain = data.map((item) => String(item));
      setCollectionIdArray(chain);
    } catch (e) {
      console.error(e);
    }
  };

  const getUsersList = async () => {
    const data = getSharedUserList(user, id);
    return data;
  };

  const shareCollectionFolder = async (email) => {
    try {
      await addUserToCollection(id, user, email);
      toast.success("Collection shared successfully.");
      getData();
    } catch (error) {
      console.error(error.response.status);
      if (error.response.status === 500) {
        toast.error("This Colelctions is already being shared with the user");
      }
      if (error.response.data === "Recipient Not Found") {
        toast.error("User not found!");
      }
    }
  };

  const removeSharedUser = async (id, sharedId, email) => {
    try {
      await deleteSharedUser(id, sharedId, user, email);
      toast.success("Shared User access removed successfully.");
      getData();
    } catch (error) {
      if (error.response.status === 500) {
        toast.error(
          `Unable to delete ${email} from shared collection. Kindly Contact admin for further support.`
        );
      }
    }
  };

  const handleDeleteCollection = async (id) => {
    try {
      await deleteCollection(user, id);
      getData();
      toast.success("Collection deleted successfully!");
    } catch (e) {
      console.error(e);
    }
  };

  const handleRenameCollection = async (id, name) => {
    try {
      await publicCollection(user, id, name);

      await getData();
      toast.success("Collection renamed successfully!");
    } catch (e) {
      console.error(e);
    }
  };

  const saveCollection = async (name, subNewFolder, id = null) => {
    try {
      let parent = {};
      if (subNewFolder) {
        parent = { parentCollectionId: id };
      }
      const data = await saveCollectionFolder(user, name, parent);

      toast.success("Collection created successfully.");
      await getData();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchParentCollection = async () => {
    const data = await getParentCollections(user, 0);

    let newArr = [];
    data.forEach((item) =>
      newArr.push({
        name: item.collectionName_chr,
        value: item.collectionName_chr,
        collectionId: item.collectionID_ids,
        parentCollectionId: item.parentCollectionID_lng,
        isShared_ysn: item.isShared_ysn,
        isPublic_ysn: item.isPublic_ysn,
      })
    );
    dispatch(setParentCollections(newArr.reverse()));
  };

  const handleSaveCustomBookmark = async (bookmark, json_res) => {
    try {
      const data = await saveCustomBookmark(json_res);
      getBookmarks(id);
      toast.success("Saved to collection");
      dispatch(setIsBookmarkSidebar(false));
    } catch (e) {
      toast.error("Something went wrong, please try later.");
    }
  };

  const handleRemoveAllSharedUsers = async () => {
    try {
      await removeAllSharedUsers(id, user);
      toast.success("Collection access to other users removed successfully!");
      getData();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCollectionOverview = async () => {
    try {
      const data = getCollectionOverview(user, id);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const makeCollectionPublic = async (isPublic = true) => {
    try {
      const data = await addUseToPublicCollection(user, id, isPublic);
      toast.success(
        `Collection made ${isPublic ? "public" : "private"} successfully.`
      );
      getData();
    } catch (e) {
      console.error(e);
    }
  };

  const handleGetData = () => {
    if (location.pathname.includes("/work-cited/") && !isSidebarOpen) {
      return;
    }
    redirectAndSetHeaderParams(collections);
    if (
      !location.pathname.includes("/public") &&
      selectedCollectionOption === "bookmarks"
    ) {
      if (id) getCollArray();
      if (!search && !(isSidebarOpen || isBookmarkSidebar)) {
        getBookmarks(id);
      }
    }
  };

  useEffect(() => {
    getData();
    fetchParentCollection();
  }, []);

  useEffect(() => {
    handleGetData();
  }, [params.id, isSidebarOpen, isBookmarkSidebar, selectedCollectionOption]);

  useEffect(() => {
    if (collectionSourceId) {
      getBookmarkData();
    }
  }, [collectionSourceId]);

  return {
    parentCollectionIds,
    currentCollection,
    bookmarks,
    filteredBookmarks,
    setFilteredBookmarks,
    collectionIdArray,
    saveCollection,
    handleSaveCustomBookmark,
    getUsersList,
    shareCollectionFolder,
    removeSharedUser,
    makeCollectionPublic,
    handleDeleteCollection,
    handleRenameCollection,
    handleRemoveAllSharedUsers,
    handleCollectionOverview,
    setBookmarks,
    collections,
  };
};

export default useCollection;
