import { filter, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useUserData } from "features/User";
import licenseLevels from "features/Group/data/group-license-levels.json";

export default function UserLicenseLevelModal() {
  const {
    gradeLicenseLevel,
    isSubscriptionLoading,
    isAuthenticated,
    updateGroupLicense,
  } = useUserData();

  const [gradeLevel, setGradeLevel] = useState("");
  const [saving, setSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [delayOpen, setDelayOpen] = useState(true);

  const shouldOpen = useMemo(() => {
    return (
      gradeLicenseLevel == null && !isSubscriptionLoading && isAuthenticated
    );
  }, [gradeLicenseLevel, isSubscriptionLoading, isAuthenticated]);

  async function handleUpdate() {
    setSaving(true);
    await updateGroupLicense(gradeLevel);
    setSaving(false);
  }

  useEffect(() => {
    setIsOpen(!delayOpen && shouldOpen);
    setTimeout(() => {
      if (isAuthenticated) {
        setDelayOpen(false);
      }
    }, 5000);
  }, [shouldOpen, delayOpen, isAuthenticated]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>User Data Update</DialogTitle>
      <DialogContent>
        <Stack>
          <DialogContentText>
            It looks like we need some more information from you to keep your
            user record up-to-date.
          </DialogContentText>
          <FormControl size="small" fullWidth>
            <InputLabel id="gradeLevelLabel">
              What is your grade level?
            </InputLabel>
            <Select
              labelId="gradeLevelLabel"
              label="What is your grade level?"
              value={gradeLevel}
              onChange={({ target }) => {
                setGradeLevel(target.value);
              }}
            >
              {sortBy(
                filter(
                  licenseLevels,
                  (level) => level.key.toLowerCase() !== "unknown"
                ),
                ["order"]
              ).map((level) => (
                <MenuItem key={level.key} value={level.key} selected>
                  {level.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "secondary.main",
        }}
      >
        {/* <Button variant="outlined">Logout</Button> */}
        <LoadingButton
          onClick={handleUpdate}
          loading={saving}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
