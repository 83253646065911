import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { emailValidation, validateUserPassword } from "utils/validation.util";
import { setEventName } from "context/features/dataSlice";
import { wordpressURL } from "config";
import { initAmplitude, logEvent } from "services/amplitude.service";
import { authAPI } from "services/apis.service";

import ServiceSignin from "components/base/login-signup/ServiceSignin";
import Terms from "components/base/login-signup/Terms";
import { LoadingButton } from "@mui/lab";

export default function SignupForm() {
  const [gradeLevel, setGradeLevel] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tc] = useState(true);
  const [loading, setloading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qrId = queryParams.get("eventName");
  const invitationCode = queryParams.get("invitationCode");
  const decodedInvitationCode = invitationCode
    ? invitationCode.replaceAll(" ", "+")
    : "";
  const { eventName } = useSelector((state) => state.data);

  useEffect(() => {
    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      gradeLevel === ""
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [email, password, confirmPassword, gradeLevel]);

  async function onSubmitLogin(e) {
    e.preventDefault();
    if (!emailValidation(email)) {
      toast.error("Please enter a valid E-mail");
      return;
    }
    if (!validateUserPassword(password)) {
      toast.error("Please enter a valid password");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password do not match");
      return;
    }
    try {
      setloading(true);
      let url;
      if (eventName) {
        url = `${authAPI.register}?eventName=${eventName}`;
      } else {
        url = authAPI.register;
      }
      const res = await axios.post(url, {
        email,
        password,
        confirmPassword,
        firstName: "",
        lastName: "",
        groupLicenseKey: gradeLevel,
        acceptTermsAndConditions: tc,
        invitationCode: String(decodedInvitationCode),
      });
      initAmplitude(res.data.userId);
      logEvent("user_registered", { email: email });
      setloading(false);

      if (res.data) {
        localStorage.setItem("email", email);
        navigate("/SignUpMessage");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data);
      setloading(false);
    }
  }

  useEffect(() => {
    if (qrId) {
      dispatch(setEventName(qrId));
    }
  }, [qrId, dispatch]);

  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bgcolor: theme.palette.secondary.main,
          p: 2,
          display: "flex",
          justifyContent: "end",
          alignItems: "flex-end",
          gap: 2,
        }}
      >
        <Typography variant="body2">Already have an account?</Typography>
        <Button LinkComponent={Link} to="/" color="primary">
          Sign In
        </Button>
      </Box>
      <Typography variant="h3">Sign Up</Typography>
      <Stack direction="column" gap={2}>
        <ServiceSignin handleLoading={setloading} />
        <Divider>
          <Chip label="OR" size="small" />
        </Divider>
        <Box
          component="form"
          onSubmit={onSubmitLogin}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            px: 5,
          }}
        >
          <FormControl size="small">
            <InputLabel id="gradeLevelLable">
              What is your grade level?
            </InputLabel>
            <Select
              labelId="gradeLevelLable"
              label="What is your grade level?"
              value={gradeLevel}
              onChange={({ target }) => {
                setGradeLevel(target.value);
              }}
            >
              <MenuItem value="group-k5" selected>
                Kindergarten-5th Grade
              </MenuItem>
              <MenuItem value="group-68">6th-8th Grade</MenuItem>
              <MenuItem value="group-912">9th-12th Grade</MenuItem>
              <MenuItem value="group-highed">Higher Education</MenuItem>
              <MenuItem value="group-gov">Government</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            id="email"
            placeholder="Email Address"
            value={email}
            type="email"
            variant="outlined"
            onChange={(e) => setemail(e.target.value)}
            helperText=""
          />
          <FormControl variant="outlined" size="small">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              required
              id="password"
              type={passwordShow ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordShow((value) => !value)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {!passwordShow ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <FormHelperText id="password-helper-text">
              NOTE: Password should be minimum 8 characters with at least one
              uppercase letter, one lowercase letter, one special character and
              one number.
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" size="small">
            <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
            <OutlinedInput
              required
              id="confirm-password"
              type={passwordShow ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordShow((value) => !value)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {!passwordShow ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </FormControl>
          <Stack direction="row" gap={2} justifyContent="end">
            <Button
              variant="outlined"
              LinkComponent={Link}
              to={process.env.REACT_APP_PRODUCTION_POLICY_URL}
            >
              Back to home
            </Button>
            <LoadingButton
              loading={loading}
              disabled={isButtonDisabled}
              type="submit"
              variant="contained"
            >
              Sign Up
            </LoadingButton>
          </Stack>
        </Box>
        <Divider />
        <Terms logEvent={logEvent} wordpressURL={wordpressURL} />
      </Stack>
    </React.Fragment>
  );
}
