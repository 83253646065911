import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import PublicCollectionSource from "./components/PublicCollectionSource";
import useCollection from "./hooks/use-public-collection.hook";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import Footer from "components/layout/Footer";
import CopyCollectionModal from "./components/CopyCollectionModal"

const PublicCollectionPage = () => {
  const [loading, setLoading] = useState(true);
  const [currentPublicCollection, setCurrentPublicCollection] = useState({});
  const [publicCollectionOverview, setPublicCollectionOverview] = useState({});
  const [copyPopup, setCopyPopup] = useState(false);

  const {
    publicCollections,
    fetchPublicCollectionsSource,
    publicCollectionsSource,
    handlePublicCollectionOverview,
    setPublicCollectionsSource,
    handlePublicCollectionCopy,
  } = useCollection(setLoading);

  const params = useParams();
  const id = params.id;

  const getPublicCollectionOverview = async () => {
    try {
      const data = await handlePublicCollectionOverview(id);
      setPublicCollectionOverview(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const handleIdChange = () => {
      fetchPublicCollectionsSource(id);
      const currentCollection = publicCollections.filter((publicCollection) => {
        return publicCollection.publicCollectionID_chr === id;
      })[0];
      setCurrentPublicCollection(currentCollection);
    };
    publicCollections.length > 0 && handleIdChange();
  }, [id, publicCollections]);

  useEffect(() => {
    if (id) {
      getPublicCollectionOverview();
    }
  }, [id]);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <PublicCollectionSource
        currentPublicCollection={currentPublicCollection}
        publicCollectionsSource={publicCollectionsSource}
        publicCollectionOverview={publicCollectionOverview}
        setPublicCollectionsSource={setPublicCollectionsSource}
        setCopyPopup={setCopyPopup}
      />
      {copyPopup && <CopyCollectionModal 
        copyPopup={copyPopup} 
        setCopyPopup={setCopyPopup}
        handlePublicCollectionCopy={handlePublicCollectionCopy}
       />}
      <Footer sx={{px: 0}} />
    </Box>
  );
};

export default PublicCollectionPage;
