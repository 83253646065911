import { axiosInstance } from "lib/axios";
import { currentEnv } from "config";

const API_URL = `https://${currentEnv}feed-api.sooth.fyi/api/FeedCategory/GetFeedCategoryForUser`;

const addFeedCategoriesForUserAPI = `https://${currentEnv}feed-api.sooth.fyi/api/FeedCategory/AddFeedCategoriesForUser`;

const getFeedSearchAPI =
  `https://${currentEnv}feed-api.sooth.fyi/api/FeedSearch/GetFeedSearch`;

const getBreakoutTopicList = 
  `https://${currentEnv}feed-api.sooth.fyi/api/FeedCategory/GetRefBreakoutTopicList`;

const breakoutTopicData =
  `https://${currentEnv}feed-api.sooth.fyi/api/FeedSearch/GetBreakoutTopicData`;

export const getFeedCategories = async (userId) => {
  try {
    const response = await axiosInstance.post(API_URL, { userId }, {cache: false});
    return response.data;
  } catch (error) {
    console.error("Error fetching feed categories:", error);
    throw error;
  }
};

export const getBreakoutTopic = async (userId) => {
  try{
    const response = await axiosInstance.get(getBreakoutTopicList, {userId});
    return response.data;
  }catch(error){
    console.error("Error Fetching Breakout Topic", error);
  }
};

export const getBreakoutTopicData = async (page, PageSize, userId, TopicCode) => {
  try {
    const response = await axiosInstance.post(breakoutTopicData, {
      PageNumber: page,
      PageSize: PageSize,
      userId,
      TopicId: 1,
    });
    return response.data.newsFeed;
  } catch (error) {
    console.error("Error Fetching Breakout Topic", error);
  }
};

export const getFeedSearch = async (
  userId,
  page,
  PageSize,
  pulseCategoryId
) => {
  try {
    const response = await axiosInstance.post(getFeedSearchAPI, {
      PageNumber: page,
      PageSize: PageSize,
      UserId: userId,
      categoryId: pulseCategoryId,
    });
    return response.data.newsFeed;
  } catch (error) {
    console.error("Error fetching feed categories:", error);
    throw error;
  }
};

export const addFeedCategoriesForUser = async (
  callingUserId,
  userId,
  categoryIds
) => {
  try {
    const response = await axiosInstance.post(addFeedCategoriesForUserAPI, {
      callingUserId,
      categoryIds,
      userId,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding feed categories for user:", error);
    throw error;
  }
};
