import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSourceSearch } from "features/SourceSearch";
import { getBoclipsSearch } from "features/News/services/boclips.service";
import { getFilterDateValueKeys } from "utils/date-filter.util";
import useBoclipsSearch from "features/News/hooks/use-boclips-search.hook";

const IDPROP = "id";

const useInfiniteScrollBoclips = (
  offset,
  setLoading,
  setcategoryData,
  setHasMore,
) => {
  const { currentSearchTerm,
      currentTimeframe,
      startDate,
      endDate } = useSourceSearch();

  const pageRef = useRef(1);

  const filteredDateValue = getFilterDateValueKeys(
    currentTimeframe,
    startDate,
    endDate
  );

  const [error, setError] = useState("");

  const getData = async () => {
    setLoading(true);

    try {
      let data = await getBoclipsSearch(
        currentSearchTerm,
        filteredDateValue,
        pageRef.current
      );
      data = data;
      const boclipsVideos = data?.embedded?.videos ? data?.embedded?.videos : [];
      const totalRecords = data?.page?.totalElements
        ? data?.page?.totalElements
        : 0;
      
      setcategoryData(boclipsVideos, IDPROP);
      setHasMore(pageRef.current * 21 < totalRecords);
    } catch (error) {
      setError(error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    setcategoryData([], IDPROP, true);
    pageRef.current = 1;
    getData();
  }, [currentSearchTerm]);

  useEffect(() => {
    if (pageRef.current) {
      if (offset !== 0) {
        getData();
        pageRef.current += 1;
      }
    }
  }, [offset]);

  return { error };
};

export default useInfiniteScrollBoclips;
