import {
  Box,
  Card,
  CardMedia,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useIsMobile } from "hooks/is-mobile.hook";
import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import defaultBackground from "assets/images/image-placeholder.png";
import React from "react";
import {
  setSelectedBookmarkOption,
  setLevel,
  setCollectionSourceId,
  setSummaryInfoData,
  setCollectionId,
  setIsCitationAvailable,
  setIsSummaryAvailable,
  setReadingLevelAvailable,
  setIsSidebarOpen,
  setBoclipsVideoId,
  setBoclipsPopup,
} from "context/features/dataSlice";
import { useDispatch } from "react-redux";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { formatDuration } from "utils/time.util";
import { searchAPI } from "services/apis.service";

const BoclipsCard = ({ data }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const dispatch = useDispatch();

  const openSidebar = () => {
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: "Boclips",
        title: data.title,
        published_date: data.releasedOn,
        url: `${searchAPI.boclipsLoadVideo}/${data.id}`,
      })
    );
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const handleTitleClick = () => {
    dispatch(setBoclipsVideoId(data.id));
    dispatch(setBoclipsPopup(true));
  };

  const BookmarkOptions = ({ data }) => {
    return (
      <Stack
        onClick={() => {
          openSidebar();
          // logEvent("open_bookmark_popup", {
          //   url: newUrl,
          //   query: query,
          //   type: "generic",
          //   entity: data.entityName,
          //   category: category,
          //   mediabiasrating: data.mediaBiasRating
          //     ? data.mediaBiasRating
          //     : "NA",
          //   score: data.score ? data.score : "NA",
          // });
        }}
        className="card-options"
        sx={{
          cursor: "pointer",
          height: "40%",
          pl: 1,
          pr: 1,
          borderRadius: 1,
          transition: "all 0.1s ease",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            "& .collection-hover": {
              opacity: "1 !important",
            },
          },
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mt={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <CollectionsLogoHover
            className="collection-hover"
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 17,
              height: 17,
              fill: "blue",
              color: "blue",
              opacity: 0,
              transition: "all 0.1s ease",
            }}
          />
        )}
        <IconButton
          aria-label="more"
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  const imageUrl = data?.thumbnail?.href
    ? data?.thumbnail?.href
    : defaultBackground;

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        boxShadow: "none",
        borderRadius: 1,
        p: 1,
        transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn}`,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <Stack direction="column" gap={1} justifyContent="space-between">
        <Box onClick={handleTitleClick} sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              borderRadius: 1,
            }}
          />
          <CardMedia
            height="150"
            component="img"
            sx={{ cursor: "pointer", borderRadius: 1 }}
            image={imageUrl}
            alt={data.title}
          />
          <PlayCircleIcon
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              fontSize: "3rem",
              color: "rgba(255, 255, 255, 0.8)",
              "&:hover": {
                color: "rgba(255, 255, 255, 1)",
              },
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              bottom: 8,
              left: 8,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              padding: "2.5px 10px",
              fontSize: "1rem",
              borderRadius: "999px",
              color: "white",
            }}
          >
            {data?.playback?.duration &&
              formatDuration(data?.playback?.duration)}
          </Typography>
        </Box>

        <Typography
          variant="body4"
          onClick={handleTitleClick}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            lineHeight: "1.5em",
            minHeight: "3em",
            maxWidth: "90%",
            flexShrink: 0,
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.light,
            },
          }}
        >
          {data.title}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              color: { xs: "black", sm: "grey" },
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              lineHeight: "1.5em",
              color: "rgb(126,127,126)",
            }}
            variant={isMobile ? "body4" : "body1"}
          >
            {data.createdBy}
          </Typography>
          <BookmarkOptions data={data} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default BoclipsCard;
