import { showBoclipsSearch } from "config";

export const sources = [
  { label: "By Categories", key: "categories", default: true },
  { label: "By Media Bias", key: "rating" },
  { label: "Peer Reviewed", key: "peer-reviewed" },
  { label: "Books", key: "books" },
  { label: "Encyclopedia & Dictionary", key: "wiki" },
];

export const boClipsLabel = "Videos";

const categories = [
  { label: "Global News", key: "global-news" },
  { label: "Periodicals & Specialty News", key: "periodics" },
  { label: "Think Tanks", key: "think-tanks" },
  { label: "Academia & Scholarly Resources", key: "academia" },
  { label: "Other Selected Sources", key: "others" },
  { label: "US Government", key: "us-government" },
  { label: "Intergovernmental Organizations", key: "intergovernment" },
]

if (showBoclipsSearch) {
  categories.push({ label: boClipsLabel, key: "boclips" });
}

export { categories };

export const academicSources = [
  { label: "CORE", key: "1" },
  { label: "Semantic Scholar", key: "2" },
  { label: "Arxiv", key: "3" },
  { label: "Springer Nature", key: "4" },
  { label: "PLOS", key: "5" },
];

export const biasCategories = [
  { label: "Left & Lean Left", key: "Left" },
  { label: "Center", key: "Center" },
  { label: "Right & Lean Right", key: "Right" },
];

export const frequencies = [
  { label: "Past 24 hours", key: "past-day" },
  { label: "Past Week", key: "past-week" },
  { label: "Past Month", key: "past-month" },
  { label: "Past Year", key: "past-year" },
  { label: "Past 2 Years", key: "past-two-years" },
  { label: "Past 3 Years", key: "past-three-years" },
  { label: "All time", key: "all-time", default: true },
  { label: "Custom Date", key: "custom-date" },
];
