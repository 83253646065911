import React from 'react'
import { getRefreshToken } from 'services/washinghton.service';

const getRefreshTOkenWashingtonPost = async (url) => {
  try{
    const newUrl = await getRefreshToken(url);
    return newUrl.data;
  }catch(e){
    console.error(e);
    return "";
  }
}

export default getRefreshTOkenWashingtonPost;