import axios from "axios";
import React, { useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailValidation } from "utils/validation.util";
import { authAPI } from "services/apis.service";

export default function ForgotPassword(props) {
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);

  async function onSubmitLogin(e) {
    e.preventDefault();

    if (!emailValidation(email)) {
      toast.error("Please enter a valid E-mail");
      return;
    }

    try {
      setloading(true);
      const res = await axios.post(authAPI.forgotPassword, {
        email,
      });
      setloading(false);

      if (res.data) {
        localStorage.setItem("email", email);
        navigate("/ResetPasswordMessage");
      }
    } catch (error) {
      if (error.response.data === "User not found") {
        toast.error(error.response.data);
      }
      console.error(error);
      toast.error(error.response.data.title);
      setloading(false);
    }
  }

  return (
    <React.Fragment>
      <Stack direction="column" gap={2}>
        <Typography variant="h3">Forgot Password?</Typography>
        <Box
          component="form"
          onSubmit={onSubmitLogin}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            px: 5,
          }}
        >
          <TextField
            id="email-username"
            placeholder="Email Address or Username"
            value={email}
            inputProps={{
              type: "email",
            }}
            variant="outlined"
            onChange={(e) => setemail(e.target.value)}
          />
          <Stack direction="row" gap={2} justifyContent="end">
            <Button variant="outlined" LinkComponent={Link} to="/">
              Back
            </Button>
            <Button loading={loading} type="submit">
              Continue
            </Button>
          </Stack>
        </Box>
      </Stack>
    </React.Fragment>
  );
}
