import { axiosInstance } from "lib/axios";
import React, { useState } from "react";
import { searchAPI } from "services/apis.service";
import { addUpdateWorkCitedService, deleteWorkCited, getCollectionsSourceWorksCited, getWorksCited, getWorksCitedDocument } from "../services/workcited.service";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { find as _find, filter } from "lodash";


const useWorkCited = (worksCited = "", setWorksCited = () => {}) => {
  const { id, workcitedid } = useParams();
  const navigate = useNavigate();

  const getWorkCited = async () => {
    try {
      const data = await getWorksCited(id);
      setWorksCited(data);
    } catch (e) {
      console.error(e);
    }
  };

const getWorkCitedbyId = async () => {
  try {
    const data = await getWorksCited(id);
    
    const matchedWorkCited = _find(data, {
      workCitedId_ids: Number(workcitedid),
    });

    return matchedWorkCited;
  } catch (e) {
    console.error(e);
    return null; 
  }
};

const getWorkCitedDocument = async (id) => {
  try {
    // Fetch document as a Blob
    const blob = await getWorksCitedDocument(id);

    // Verify it's a Blob instance
    if (!(blob instanceof Blob)) {
      throw new Error("Response is not a Blob");
    }

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    const fileName = `works-cited-${id}-${
      new Date().toISOString().split("T")[0]
    }.docx`;

    link.href = url;
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }, 100);
  } catch (error) {
    console.error("Error downloading document:", error);
  }
};


  const deleteWorkCitedDocument = async (id) => {
    try {
      await deleteWorkCited(id);
      const data = filter(
        worksCited,
        ({ workCitedId_ids }) => workCitedId_ids !== id
      );
      setWorksCited(data);
      toast.success("WorkS Cited deleted successfully!");
    } catch (e) {
      console.error(e);
    }
  };

  const addUpdateWorkCited = async ({
    workCitedId,
    collectionId,
    name,
    formatStyle,
    collectionSourceIds,
    create,
  }) => {
    try {
      const data = await addUpdateWorkCitedService({
        workcitedId: workCitedId,
        collectionId: collectionId,
        name: name,
        formatStyle: formatStyle,
        collectionSourceIds: collectionSourceIds,
      });
      toast.success(`${name} Works Cited ${create ? "created ": "updated "} successfully!`);
      navigate(`/collections/${collectionId}/work-cited`);
    } catch (e) {
      console.error(e);
    }
  };

  const getCollectionSourceForWorkCited = async ({
    workCitedId,
    collectionId,
    name,
    formatStyle,
    seCollectionSourceIds,
  }) => {    
    try {
      const data = await getCollectionsSourceWorksCited({
        workcitedId: workCitedId,
        collectionId: collectionId,
        name: name,
        formatStyle: formatStyle,
      });
      seCollectionSourceIds(data);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getWorkCited,
    getWorkCitedDocument,
    addUpdateWorkCited,
    getCollectionSourceForWorkCited,
    deleteWorkCitedDocument,
    getWorkCitedbyId,
  };
};

export default useWorkCited;
