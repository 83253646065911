import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Alert,
  Skeleton,
  Stack,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

import {
  fetchSourceData,
  getFullNutritionLabelLink,
} from "../services/source-data.service";
import { fillParent, scrollingBox } from "utils/base-styles";
import { parse } from "tldts"; // Import tldts parser

import NewsguardScore from "components/base/NewsguardScore";
import newsGuardImg from "assets/images/newsguard.png";
import three_star from "assets/images/three_star.svg";
import four_star from "assets/images/four_star.svg";
import one_star from "assets/images/one_star.svg";
import zero_star from "assets/images/zero_star.svg";
import two_star from "assets/images/two_star.svg";
import NewsguardCriteria from "./NewsGuardCriteria";
import SourceInfoBox from "./SourceInfoBox";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { mediaBiasContent } from "data/mediaBiasContent";
import { logEvent } from "services/amplitude.service";
import AllSidesLogo from "components/base/AllSidesLogo";
import { mediaBiasUrl } from "data/mediabias-url";

const SourceInfo = ({ handleClose }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const navbarBackground = theme.palette.secondary.main;

  const { sourceUrl, entityName, otherLabelsNeeded } = useSelector(
    (state) => state.data
  );

  // const publisherData = useSourceData(sourceUrl);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [nutritionLoading, setNutritionLoading] = useState(false);
  const [labelLink, setLabelLink] = useState("");
  const [showMore, setShowMore] = useState(false);
  const maxChars = 600;

  const getSourceData = async (sourceUrl) => {
    setIsLoading(true);
    try {
      const data = await fetchSourceData(sourceUrl);
      const json = data.data;
      setData(json);
    } catch (error) {
      console.error("Failed to Publisher Data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNutritionLink = async () => {
    if (labelLink) {
      window.open(labelLink, "_blank");
      return;
    }

    setNutritionLoading(true);

    const newTab = window.open("about:blank", "_blank");

    try {
      const url = await getFullNutritionLabelLink(data.hostname, data.score);

      setLabelLink(url);

      if (newTab) {
        newTab.location.href = url;
      }
    } catch (error) {
      console.error("Failed to Fetch Nutrition Data", error);
      if (newTab) {
        newTab.close();
      }
    } finally {
      setNutritionLoading(false);
    }
  };

  useEffect(() => {
    getSourceData(sourceUrl);
  }, [sourceUrl]);

  const charity_rating = () => {
    if (data.charityNavigatorScore > 90 || data.charityNavigatorRating === 4) {
      return four_star;
    } else if (
      data.charityNavigatorScore >= 75 ||
      data.charityNavigatorRating === 3
    ) {
      return three_star;
    } else if (
      data.charityNavigatorScore >= 60 ||
      data.charityNavigatorRating === 2
    ) {
      return two_star;
    } else if (
      data.charityNavigatorScore >= 50 ||
      data.charityNavigatorRating === 1
    ) {
      return one_star;
    }
    return zero_star;
  };

  const charity_text = () => {
    if (data.charityNavigatorScore >= 90) {
      return "four-star";
    } else if (data.charityNavigatorScore >= 75) {
      return "three-star";
    } else if (data.charityNavigatorScore >= 60) {
      return "two-star";
    } else if (data.charityNavigatorScore >= 50) {
      return "one-star";
    }
    return "zero-star";
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: `minmax(auto, auto) 1fr`,
        minHeight: "100%",
        minWidth: "35vw",
      }}
    >
      <Box
        sx={{
          backgroundColor: navbarBackground,
          p: 3,
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ color: "#696b6c", fontSize: "2rem" }}>
            {isLoading ? <Skeleton variant="text" width={100} /> : entityName}
          </Typography>
          <IconButton>
            <CloseIcon
              onClick={handleClose}
              style={{ height: 18, width: 18 }}
            />
          </IconButton>
        </Box>
        {data && (data?.wikipediaSnippet || data.snippet) && (
          <Box sx={{ mt: 1 }}>
            {isLoading ? (
              <Skeleton variant="rectangular" height={100} />
            ) : (
              <>
                {data?.wikipediaSnippet ? (
                  <Typography variant="body1" component="div">
                    {(() => {
                      const fullText = data.wikipediaSnippet.replace(
                        /\n+$/,
                        ""
                      );
                      const isLong = fullText.length > maxChars;
                      return showMore || !isLong
                        ? fullText
                        : fullText.slice(0, maxChars) + "...";
                    })()}

                    {data.wikipediaSnippet.replace(/\n+$/, "").length >
                      maxChars && (
                      <Typography
                        variant="body2"
                        color="primary"
                        onClick={() => setShowMore((prev) => !prev)}
                        sx={{ cursor: "pointer", mt: 1 }}
                      >
                        {showMore ? "Show Less" : "Show More"}
                      </Typography>
                    )}

                    {data?.wikipediaUrl && (
                      <Typography sx={{ mt: 1, color: "grey" }}>
                        - Source:{" "}
                        <Link
                          href={data.wikipediaUrl}
                          target="_blank"
                          underline="hover"
                          sx={{ color: "inherit", textDecoration: "none" }}
                        >
                          Wikipedia
                        </Link>
                      </Typography>
                    )}
                  </Typography>
                ) : (
                  data?.snippet && (
                    <>
                      <Typography variant="body1" component="div">
                        {(() => {
                          const fullText = data.snippet.replace(/\n+$/, "");
                          const isLong = fullText.length > maxChars;
                          return showMore || !isLong
                            ? fullText
                            : fullText.slice(0, maxChars) + "...";
                        })()}
                      </Typography>

                      {data.snippet.replace(/\n+$/, "").length > maxChars && (
                        <Typography
                          variant="body2"
                          color="primary"
                          onClick={() => setShowMore((prev) => !prev)}
                          sx={{ cursor: "pointer", mt: 1 }}
                        >
                          {showMore ? "Show Less" : "Show More"}
                        </Typography>
                      )}

                      {data.url && (
                        <Typography sx={{ mt: 1, color: "grey" }}>
                          - Source :{" "}
                          <Link
                            href={data.url}
                            target="_blank"
                            underline="hover"
                            sx={{ color: "inherit", textDecoration: "none" }}
                          >
                            {data.url
                              .toLowerCase()
                              .includes(
                                entityName.toLowerCase().replace(/\s+/g, "")
                              )
                              ? entityName
                              : parse(data.url)?.domain}
                          </Link>
                        </Typography>
                      )}
                    </>
                  )
                )}
              </>
            )}
          </Box>
        )}
      </Box>

      {data ? (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 3,
              gap: 3,
              mb: isMobile && 6,
              ...fillParent,
              ...scrollingBox,
            }}
          >
            {!isLoading ? (
              <React.Fragment>
                {data.score ? (
                  <SourceInfoBox
                    title="NewsGuard Score"
                    header={<NewsguardScore score={data.score} size="large" />}
                    buttonText="Full Nutrition Label"
                    buttonLink={labelLink}
                    loading={nutritionLoading}
                    onClick={() => {
                      getNutritionLink();
                      logEvent("click_newsguard_nutritionlabel", {
                        click: true,
                        link: getNutritionLink,
                      });
                    }}
                    logo={
                      <img
                        style={{
                          width: "10rem",
                          cursor: "pointer",
                        }}
                        src={newsGuardImg}
                        onClick={() => {
                          window.open(
                            "https://www.newsguardtech.com/about-newsguard/",
                            "blank"
                          );
                          logEvent("click_newsguard_logo", {
                            click: true,
                            link: "https://www.newsguardtech.com/about-newsguard/",
                          });
                        }}
                        alt="news guard"
                      />
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Typography color="primary.dark" variant="h6">
                          Credibility
                        </Typography>
                        <NewsguardCriteria
                          value={data.falseContent}
                          criteria="Does not repeatedly publish false or egregiously misleading content"
                        />
                        <NewsguardCriteria
                          value={data.basicStandards}
                          criteria="Gathers and presents information responsibly"
                        />
                        <NewsguardCriteria
                          value={data.accountabilityPractices}
                          criteria="Has effective practices for correcting errors"
                        />
                        <NewsguardCriteria
                          value={data.newsOpinion}
                          criteria="Handles the difference between news and opinion
                        responsibly"
                        />
                        <NewsguardCriteria
                          value={data.deceptiveHeadlines}
                          criteria="Avoids deceptive headlines"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Typography color="primary.dark" variant="h6">
                          Transparency
                        </Typography>
                        <NewsguardCriteria
                          value={data.ownership}
                          criteria="Website discloses ownership and financing"
                        />
                        <NewsguardCriteria
                          value={data.labelsAdvertising}
                          criteria="Clearly labels advertising"
                        />
                        <NewsguardCriteria
                          value={data.management}
                          criteria="Reveals who's in charge, including any possible
                        conflicts of interest"
                        />
                        <NewsguardCriteria
                          value={data.contentCreators}
                          criteria="This site provides the names of content creators,
                        along with either contact or bigraphical information"
                        />
                      </Box>
                    </Box>
                  </SourceInfoBox>
                ) : (
                  <Alert variant="outlined" icon={false} severity="warning">
                    Credibility score is unavailable
                  </Alert>
                )}
                {otherLabelsNeeded &&
                  (data.mediaBiasRating ? (
                    <SourceInfoBox
                      title="AllSides Media Bias Rating"
                      header={
                        <Link
                          href={mediaBiasUrl(data.mediaBiasRating)}
                          target="_blank"
                          onClick={() => {
                            logEvent("click_allsides_url", {
                              click: true,
                              link: mediaBiasUrl(data.mediaBiasRating),
                            });
                          }}
                        >
                          <Typography fontSize="1.8rem">
                            {data.mediaBiasRating}
                          </Typography>
                        </Link>
                      }
                      buttonText="Full Info"
                      buttonLink={data.allsidesUrl}
                      onClick={() =>
                        logEvent("click_allsides_mediabiasrating", {
                          click: true,
                          link: data.allsidesUrl,
                        })
                      }
                      logo={
                        <Link
                          href="https://www.allsides.com/about"
                          target="_blank"
                          onClick={() => {
                            logEvent("click_allsides_logo", {
                              click: true,
                              link: "https://www.allsides.com/about",
                            });
                          }}
                        >
                          <AllSidesLogo fontSize="2rem" />
                        </Link>
                      }
                    >
                      {data.mediaBiasRating && (
                        <AllSidesRating
                          activeCategory={data.mediaBiasRating}
                          size="large"
                        />
                      )}
                      {data.mediaBiasRating && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          {mediaBiasContent(data.mediaBiasRating)}
                        </Typography>
                      )}
                    </SourceInfoBox>
                  ) : (
                    <Alert variant="outlined" icon={false} severity="warning">
                      Media bias rating is unavailable
                    </Alert>
                  ))}

                {otherLabelsNeeded &&
                  (data.charityNavigatorScore || data.charityNavigatorRating ? (
                    <SourceInfoBox
                      title="Charity Navigator"
                      header={
                        data.charityNavigatorScore ? (
                          <Typography fontSize="1.8rem">
                            {data.charityNavigatorScore}%
                          </Typography>
                        ) : null
                      }
                      buttonText="Full Review"
                      onClick={() =>
                        logEvent("click_charity_review", {
                          click: true,
                          link: data.charityNavigatorEIN,
                        })
                      }
                      buttonLink={data.charityNavigatorEIN}
                      logo={
                        <img
                          src="/images/CharityNav_Logo.png"
                          style={{
                            width: "10rem",
                            cursor: "pointer",
                          }}
                          className="charity-logo"
                          alt="logo"
                        />
                      }
                    >
                      {data.charityNavigatorRating ? (
                        <img
                          style={{
                            width: "10rem",
                            height: "2rem",
                            marginLeft: -2,
                          }}
                          src={charity_rating()}
                          alt="Charity rating"
                          onClick={() => {
                            window.open(
                              "https://www.charitynavigator.org/about-us/",
                              "_blank"
                            );
                            logEvent("click_charity_logo", {
                              click: true,
                              link: "https://www.charitynavigator.org/about-us/",
                            });
                          }}
                        />
                      ) : null}

                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {data.charityNavigatorRating &&
                          `The Charity Score is ${
                            data.charityNavigatorScore
                          }%, earning it a ${charity_text()} rating.`}
                      </Typography>
                    </SourceInfoBox>
                  ) : null)}
              </React.Fragment>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  minHeight: "100%",
                  gap: 2,
                  flexDirection: "column",
                }}
              >
                <Skeleton variant="rectangular" height={150} />
                <Skeleton variant="rectangular" height={150} />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        otherLabelsNeeded && (
          <Stack sx={{ p: 3 }} direction={"column"}>
            <Alert variant="outlined" icon={false} severity="warning">
              Credibility score is unavailable
            </Alert>
            <Alert variant="outlined" icon={false} severity="warning">
              Media bias rating is unavailable
            </Alert>
          </Stack>
        )
      )}
    </Box>
  );
};

export default SourceInfo;
