import { find } from "lodash";
import Hashes from "jshashes";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";
import { setsearch } from "context/features/dataSlice";
import { getFilterDateValueKeys } from "utils/date-filter.util";
import { useSourceSearch } from "features/SourceSearch";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const MD5 = new Hashes.MD5();

const IDPROP = "url";

export default function useInfiniteScrollCategory(
  offset,
  setloading,
  setcategoryData,
  setHasMore
) {
  const dispatch = useDispatch();

  const initialRenderRef = useRef(true);

  const {
    currentSearchTerm,
    currentCategory,
    currentSubCategory,
    currentTimeframe,
    startDate,
    endDate,
    sourceCategories,
    currentSource,
    peerreviewed,
  } = useSourceSearch();
  const {category : categoryParam} = useParams();

  const { user } = useSelector((state) => state.auth);

  const [error, setError] = useState(false);

  async function getCategoryData(searchTerm, mediaId, timePeriod, offset) {
    const category = find(sourceCategories, { key: currentCategory });
    try {
      const { data } = await axiosInstance.post(
        searchAPI.search,
        {
          SearchTerm: searchTerm,
          Media: mediaId,
          timePeriod,
          Count: "21",
          Offset: `${offset}`,
        },
        {
          id: MD5.hex(
            `categorySearch:${searchAPI.search}:${searchTerm}:${mediaId}:${timePeriod}:${offset}`
          ),
        }
      );

      return {
        newData: data.data.webPages != null ? data.data.webPages.value : null,
        totalRecords:
          data.data.webPages != null
            ? data.data.webPages.totalEstimatedMatches
            : 0,
      };
    } catch (error) {
      throw error;
    }
  }

  async function getAcademicSourceData(sourceId, searchTerm, offset) {
    try {
      const { data } = await axiosInstance.post(
        searchAPI.academiaSearch,
        {
          query: searchTerm,
          offset: `${offset}`,
          academiaId: sourceId,
          limit: "10",
        },
        {
          id: MD5.hex(
            `academicSourceSearch:${searchAPI.academiaSearch}:${searchTerm}:${sourceId}:${offset}`
          ),
        }
      );

      return {
        newData: data.data ? data.data.results : null,
        totalRecords: data.data ? data.data.totalEstimatedMatches : 0,
      };
    } catch (error) {
      throw error;
    }
  }


  async function getData(offset = 0) {
    const categories = [
      ...sourceCategories,
      { label: "Peer Reviewed", key: "peer-reviewed" },
    ];
    const category = find(categories, { key: currentCategory ? currentCategory: currentSource });
    
    if(!category) return;
    if (category === null && !peerreviewed) return;
    if (category.label !== categoryParam && category.key !== currentSource) return;
    const filteredDateValue = getFilterDateValueKeys(
      currentTimeframe,
      startDate,
      endDate
    );
    setloading(true);
    try {
      if (currentSubCategory) {
        const { newData, totalRecords } = await getAcademicSourceData(
          Number(currentSubCategory),
          currentSearchTerm,
          offset
        );
        setcategoryData(newData ? newData : [], IDPROP);
        setHasMore(newData != null && offset < totalRecords);
      } else {
        const { newData, totalRecords } = await getCategoryData(
          currentSearchTerm,
          category.label,
          filteredDateValue,
          offset
        );
        setcategoryData(newData ? newData : [], IDPROP);
        setHasMore(newData != null && offset < totalRecords);
      }
    } catch (error) {
      console.error(error);
      setHasMore(false);
    } finally {
      setloading(false);
    }
  }

  useEffect(() => {
    async function fetchAPI() {
      await getData();
    }
    if (currentSearchTerm.length) {
      setcategoryData([], IDPROP, true);
      initialRenderRef.current = true;
      fetchAPI();
    }
  }, [
    currentSearchTerm,
    currentSource,
    currentCategory,
    currentTimeframe,
    currentSubCategory,
    peerreviewed,
  ]);


  useEffect(() => {
    async function fetchAPI() {
      await getData(offset);
    }
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    } else {
      if (offset !== 0) {
        fetchAPI();
      }
    }
  }, [offset]); 

  return { error };
}
