import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchCitationFields } from "features/Bookmarks/services/citation.service";

const useCitationFields = () => {
  const { user } = useSelector((state) => state.auth);
  const { itemTypes } = useSelector((state) => state.data);

  const fetchFields = async (
    switchIndex,
    currentItemType,
    baseFields,
    setLoading,
    setFields,
    setRequiredFields
  ) => {
    if (!currentItemType) return;

    setLoading(true);
    let dynamicFields = {},
      baseFieldMappings = {},
      requiredFields = [],
      currentItem = "";

    if (currentItemType.length > 0)
      currentItem = itemTypes.filter(
        (item) => item.key.toLowerCase() === currentItemType.toLowerCase()
      )[0];
    try {
      const data = await fetchCitationFields(user, currentItem);

      const response = data[0]?.dynamicFields.forEach((item) => {
        dynamicFields[item.fieldName_chr] = item.displayFieldName_chr;
        if (item.baseFieldName_chr) {
          baseFieldMappings[item.fieldName_chr] = item.baseFieldName_chr;
        }
        if (item.isRequired_ysn) {
          requiredFields.push(item.fieldName_chr);
        }
      });

      const authors = data[0].creators.reduce((acc, item) => {
        if (item.isDefault_ysn) {
          if (item.creatorName_chr === "author") {
            acc["creators"] = "creators";
          } else {
            acc[item.creatorName_chr] = item.displayName_chr;
          }
        }
        if (item.isRequired_ysn) {
          if (item.creatorName_chr === "author") {
            requiredFields.push("creators");
          } else requiredFields.push(item.creatorName_chr);
        }
        return acc;
      }, {});

      setFields({ ...authors, ...dynamicFields });
      setRequiredFields(requiredFields);
      return {
        fields: { ...authors, ...dynamicFields },
        baseFields: baseFieldMappings,
      };
    } catch (e) {
      console.error(e);
    }

    return {
      fields: {},
      baseFields: {},
    };
  };

  return { fetchFields };
};

export default useCitationFields;
