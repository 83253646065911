import {
  setBookmarkedData,
  setCollectionId,
  setCollectionSourceId,
  setCollections,
  setSummaryInfoData,
  setParentCollections,
  setSummary,
  setReadingSummary,
  setLevel,
  setReadingLevelAvailable,
} from "context/features/dataSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addOrUpdateBookmark,
  createNewCollection,
  deleteBookmark,
  getCollections,
  getCollectionsSource,
  getParentCollections,
  saveCitation,
} from "../services/collection.service";
import { toast } from "react-toastify";
import { logEvent } from "services/amplitude.service";

const useCollection = (
  setSelectedCollection,
  selectedStyle,
  authors,
  setmarkdownSummary,
  setmarkdownLevel,
  level,
  setOriginalSummary = () => {},
  setOriginalLevelSummary = () => {}
) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const {
    collections,
    summaryInfoData,
    collectionSourceId,
    collectionId,
    parentCollections,
  } = useSelector((state) => state.data);

  const deleteBookmarkSource = async () => {
    try {
      await deleteBookmark(user, collectionSourceId);
      dispatch(
        setBookmarkedData({
          id: summaryInfoData.url,
          isCitationAvailable: false,
          isSummaryAvailable: false,
          collectionId: null,
          collectionSourceId: null,
        })
      );
      logEvent("deletePopup", { bookmark: collectionSourceId });

      toast.success("Bookmark Deleted Successfully!");
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    const data = await getCollections(user);

    let newArr = [];
    data.forEach((item) =>
      newArr.push({
        name: item.collectionName_chr,
        value: item.collectionName_chr,
        collectionId: item.collectionID_ids,
        parentCollectionId: item.parentCollectionID_lng,
        isShared_ysn: item.isShared_ysn,
        isPublic_ysn: item.isPublic_ysn,
      })
    );
    dispatch(setCollections(newArr));
  };

  const getBookmarkData = async () => {
    try {
      if (collectionSourceId) {
        const res = await getCollectionsSource(
          collectionId,
          collectionSourceId
        );
        const data = res[0];
        
        if (data.isSummaryAvailable_ysn)
          setmarkdownSummary(data.summary_chr);
        setOriginalSummary(data.summary_chr);
        if (data.isReadingLevelAvailable_ysn)
          setmarkdownLevel(data.articleComprehension_chr);
          setOriginalLevelSummary(data.articleComprehension_chr);
          dispatch(setReadingSummary(data.articleComprehension_chr));
          dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable_ysn));
        if (data) {
          dispatch(setLevel(data.comprehensionLevel_chr));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchParentCollection = async () => {
    const data = await getParentCollections(user, 0);

    let newArr = [];
    data.forEach((item) =>
      newArr.push({
        name: item.collectionName_chr,
        value: item.collectionName_chr,
        collectionId: item.collectionID_ids,
        parentCollectionId: item.parentCollectionID_lng,
        isShared_ysn: item.isShared_ysn,
        isPublic_ysn: item.isPublic_ysn,
      })
    );
    dispatch(setParentCollections(newArr));
  };

  const saveNewCollection = async (name) => {
    try {
      const data = await createNewCollection(user, name, collectionId);

      const obj = {
        name: name,
        value: name,
        collectionId: data.collectionID_ids,
        parentCollectionId: collectionId,
      };
      const newCollections = [obj, ...collections];
      if (!collectionId) {
        dispatch(setParentCollections(newCollections));
      }
      dispatch(setCollectionId(data.collectionID_ids));
      setSelectedCollection(data.collectionID_ids);
      await getData();

      return data.collectionID_ids;
    } catch (e) {
      console.error(e);
    }
  };

  const createOrUpdateBookmark = async (
    markdownSummary,
    markdownLevel,
    levelLoaded = false,
    summaryLoaded = false
  ) => {
    const collectionID_ids = collectionId
      ? collectionId
      : collections[0].collectionId;

    const data = await addOrUpdateBookmark(
      user,
      summaryInfoData,
      markdownSummary,
      collectionID_ids,
      collectionSourceId,
      markdownLevel,
      levelLoaded,
      summaryLoaded,
      level
    );
    toast.success("Bookmark successfully saved to collection.");

    dispatch(setCollectionSourceId(data.collectionSourceID_ids));

    return data.collectionSourceID_ids;
  };

  const switchBookmark = async (collectionId) => {
    const data = await addOrUpdateBookmark(
      user,
      summaryInfoData,
      "",
      collectionId,
      collectionSourceId,
      (level = null)
    );
    dispatch(setCollectionId(collectionId));
    dispatch(setCollectionSourceId(data.collectionSourceID_ids));
    dispatch(
      setBookmarkedData({
        id: summaryInfoData.url,
        collectionId: collectionId,
        collectionSourceId: data.collectionSourceID_ids,
        switch: true,
      })
    );
  };

  const editBookmark = async (
    articleData,
    citation,
    markdownSummary,
    citationLoaded,
    levelLoaded,
    markdownLevel,
    summaryLoaded
  ) => {
    let id = collectionSourceId,
      collectionID_ids = collectionId;

    try {
      id = await createOrUpdateBookmark(
        markdownSummary,
        markdownLevel,
        levelLoaded,
        summaryLoaded
      );

      if (citationLoaded) {
        await saveCitation(
          articleData,
          id,
          user,
          citation,
          selectedStyle,
          authors
        );
      }
      if (!collectionId) {
        collectionID_ids = collections[0].collectionId;
        dispatch(setCollectionId(collections[0].collectionId));
      }
      dispatch(
        setBookmarkedData({
          id: summaryInfoData.url,
          isCitationAvailable: citationLoaded,
          isSummaryAvailable: markdownSummary.length > 0,
          collectionId: collectionID_ids,
          collectionSourceId: id,
        })
      );
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong, please try later.");
    }
  };

  useEffect(() => {
    getData();
    fetchParentCollection();
    getBookmarkData();
  }, []);

  return {
    editBookmark,
    saveNewCollection,
    createOrUpdateBookmark,
    switchBookmark,
    deleteBookmarkSource,
  };
};

export default useCollection;
