import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link as MuiLink,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { authAPI } from "services/apis.service";

const EmailMessage = ({ type }) => {
  const [open, setOpen] = useState(false);

  async function resendSignupConfirmation() {
    try {
      const res = await axios.post(
        authAPI.resendConfirmation,
        {},
        { params: { email: localStorage.getItem("email") } }
      );

      if (res.data) {
        toast.success(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function resendForgotConfirmation() {
    try {
      const res = await axios.post(authAPI.forgotPassword, {
        email: localStorage.getItem("email"),
      });

      if (res.data) {
        toast.success(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handlePopup(value) {
    setOpen(value);
  }
  return (
    <React.Fragment>
      <Stack direction="column" gap={2}>
        <Typography variant="h3">
          {type === "signup" ? "Sign Up" : "Reset Password"}
        </Typography>
        <Typography>
          A confirmation email is on its way. The email will be from{" "}
          <Typography component="span" fontWeight="bold">
            info@sooth.fyi
          </Typography>
          .
        </Typography>
        <Typography>Don't see it?</Typography>
        <List>
          <ListItem disableGutters disablePadding>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText>Check your junk folder</ListItemText>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText>Check your spam folder</ListItemText>
          </ListItem>
        </List>
        <Typography>Then click the resend button below.</Typography>
        {type === "resetpassword" && (
          <Stack direction="row" gap={2}>
            <MuiLink href="/">
              <Button variant="outlined">Go Back</Button>
            </MuiLink>
            <Button onClick={resendForgotConfirmation}>
              Send e-mail again
            </Button>
          </Stack>
        )}
        {type === "signup" && (
          <React.Fragment>
            <Button onClick={resendSignupConfirmation}>
              Resend Confirmation Email
            </Button>
            <Typography>
              Still don't see it? That's okay!
              <Button
                variant="text"
                onClick={() => handlePopup(true)}
                underline="always"
              >
                We can fix it!
              </Button>
            </Typography>
          </React.Fragment>
        )}
      </Stack>
      {open && (
        <Dialog onClose={() => handlePopup(false)} open={open}>
          <DialogTitle>Help! I need to confirm my email!</DialogTitle>
          <DialogContent>
            <List
              disablePadding
              component="ol"
              sx={{
                listStyle: "decimal",
                "& > li": {
                  display: "list-item",
                },
              }}
            >
              <ListItem disablePadding>
                Open a blank email from the account you are trying to
                authenticate.
              </ListItem>
              <ListItem disablePadding>
                Put in the subject line "I need to confirm my email".
              </ListItem>
              <ListItem disablePadding>
                Send the email to{" "}
                <MuiLink
                  fontWeight="bold"
                  href="mailto:emailsupport@sooth.fyi?subject=I%20need%20to%20confirm%20my%20email"
                >
                  [emailsupport@sooth.fyi].
                </MuiLink>
              </ListItem>
              <ListItem disablePadding>
                Wait 5 minutes and try to sign in using your email and password.
              </ListItem>
              <ListItem disablePadding>
                If you are still experiencing difficulty, contact us here{" "}
                <MuiLink href="mailto:contact@sooth.fyi">
                  [contact@sooth.fyi].
                </MuiLink>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handlePopup(false)}>Okay</Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default EmailMessage;
