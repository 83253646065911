import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";

export const fetchNewsData = async (query, user, filterTerm, category) => {
  const { data } = await axiosInstance.post(searchAPI.search, {
    SearchTerm: query,
    Media: category,
    Count: "21",
    Offset: "0",
    timePeriod: filterTerm,
    userId: user.userId,
  });
  return data.data?.webPages?.value;
};


export const fetchCombinedSearch = async (query, filterTerm, media) => {
  const { data } = await axiosInstance.post(searchAPI.getCombinedSearch, {
    searchTerm: query,
    timePeriod: filterTerm,
    media: media,
    count: "21",
    offset: "0",
  });
  return data.data;
};