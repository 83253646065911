import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useMemo } from "react";

export default function AllSidesCategoryIcon({
  category,
  color,
  active,
  size,
}) {
  const theme = useTheme();
  // const [dimension, setDimension] = useState("1.5rem");
  // const [fontSize, setFontSize] = useState("1.3rem");
  const dimension = useMemo(() => {
    switch (size) {
      case "small":
        return "1.2rem";
      case "large":
        return "2.4rem";
      default:
        return "1.5rem";
    }
  }, [size]);
  const fontSize = useMemo(() => {
    switch (size) {
      case "small":
        return "1rem";
      case "large":
        return "2.2rem";
      default:
        return "1.3rem";
    }
  }, [size]);
  return (
    <Box
      width={dimension}
      height={dimension}
      sx={{
        bgcolor: active
          ? color
          : theme.palette.mode === "dark"
          ? theme.palette.grey[600]
          : theme.palette.grey[300],
        color: theme.palette.common.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        p: 0,
        fontSize: fontSize,
      }}
    >
      {category}
    </Box>
  );
}
