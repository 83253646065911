import { useState, useEffect } from "react";
import { fetchCitationStyles } from "features/Bookmarks/services/citation.service";
import { useSelector } from "react-redux";

const useCitationStyles = (
  selectedStyle,
  setSelectedStyle,
  selectedBookmarkOption,
  citationLoaded
) => {
  const [citationStyles, setCitationStyles] = useState([]);

  const url = useSelector((state) => state.data.summaryInfoData.url);

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const data = await fetchCitationStyles();
        const options = data.map((style) => ({
          name: style.title,
          key: style.name,
        }));
        setSelectedStyle(data[0].name);
        setCitationStyles(options);
        // if (!collectionIdid || !citationAvailable)
        //   setCurrentStyle(opts[0]);
      } catch (e) {
        console.error(e);
      }
    };

    if (!citationLoaded && selectedBookmarkOption === "citation") fetchStyles();
  }, [selectedBookmarkOption]);

  return { citationStyles, selectedStyle, setSelectedStyle };
};

export default useCitationStyles;
