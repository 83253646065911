import { Stack } from "@mui/material";
import AllSidesCategoryIcon from "./AllSidesCategoryIcon";

export default function AllSidesRating({
  activeCategory,
  size = "medium",
  single = false,
}) {
  if (!["small", "medium", "large"].includes(size)) {
    console.error(
      Error("Size prop only allows 'small', 'medium', and 'large' values")
    );
  }
  const renderSingleCategoryIcon = (category) => {
    switch (category) {
      case "Left":
        return (
          <AllSidesCategoryIcon
            size={size}
            category="L"
            color="#126ba3"
            active
          />
        );
      case "Lean Left":
        return (
          <AllSidesCategoryIcon
            size={size}
            category="L"
            color="#a3becf"
            active
          />
        );
      case "Center":
        return (
          <AllSidesCategoryIcon
            size={size}
            category="C"
            color="#6e3aa6"
            active
          />
        );
      case "Lean Right":
        return (
          <AllSidesCategoryIcon
            size={size}
            category="R"
            color="#bd6c81"
            active
          />
        );
      case "Right":
        return (
          <AllSidesCategoryIcon
            size={size}
            category="R"
            color="#a10830"
            active
          />
        );
      default:
        return null; // Handle edge cases where activeCategory doesn't match
    }
  };

  return !single ? (
    <Stack direction="row" gap={0.5}>
      <AllSidesCategoryIcon
        size={size}
        category="L"
        color="#126ba3"
        active={activeCategory === "Left"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="L"
        color="#a3becf"
        active={activeCategory === "Lean Left"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="C"
        color="#6e3aa6"
        active={activeCategory === "Center"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="R"
        color="#bd6c81"
        active={activeCategory === "Lean Right"}
      />
      <AllSidesCategoryIcon
        size={size}
        category="R"
        color="#a10830"
        active={activeCategory === "Right"}
      />
    </Stack>
  ) : (
    <Stack direction="row" gap={0.5}>
      {renderSingleCategoryIcon(activeCategory)}
    </Stack>
  );
}
