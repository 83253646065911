import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setsearch } from "context/features/dataSlice";
import { logoutUser } from "context/features/authSlice";
import CustomLoader from "components/base/loader";

export default function CleverLoggedIn() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleCleverLogin = async (provider) => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code === localStorage.getItem("clever")) {
      navigate("/");
      return;
    }

    if (code) {
      dispatch(logoutUser());
      localStorage.removeItem("user");
      dispatch(setsearch(""));
    }
  };

  useEffect(() => {
    handleCleverLogin("clever");
  }, [location.search]);

  <div className="circular-loader-center">
    <CustomLoader />
  </div>;
};
