import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchAPI } from "services/apis.service";
import { setItemsPage } from "context/features/dataSlice";
import { axiosInstance } from "lib/axios";

const useDocumentTypes = (userId) => {
  const dispatch = useDispatch();
  const itemTypes = useSelector((state) => state.data.itemTypes);
  const fetchedRef = useRef(false);

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      if (!fetchedRef.current && !itemTypes.length) {
        fetchedRef.current = true;
        try {
          const { data } = await axiosInstance.post(
            searchAPI.GetDocumentTypes,
            {
              userId: userId,
            }
          );
          const Items = data.map((document) => ({
            name: document.displayName_chr,
            key: document.name_chr,
            id: document.itemTypeId_ids,
          }));
          dispatch(setItemsPage(Items));
        } catch (e) {
          console.error(e);
        }
      }
    };

    fetchDocumentTypes();
  }, [userId, itemTypes.length, dispatch]);
};

export default useDocumentTypes;
